export default {
  public: [],
  admin: [
    // 影片列表
    {
      path: 'video',
      name: 'video',
      component: () => import('@/modules/video/views/videoList.vue'),
    },

    // 影片表單
    {
      path: 'video/create',
      name: 'video-create',
      meta: {
        formMode: 'create',
      },
      component: () => import('@/modules/video/views/videoForm.vue'),
    },
    {
      path: 'video/update/:target',
      name: 'video-update',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/video/views/videoForm.vue'),
    },
  ],
}
